<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button/>
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">Change {{ response.program.first_name }} Password</h1>
    </div>
    <div v-loading="loaders.program" class="mt-4">
      <div class="forms">
        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
          <form role="form" @submit.prevent="handleSubmit(onSubmit)">
            <card type="default" header-classes="bg-transparent" class="col-lg-6 p-0">
              <div class="col">
                <base-input
                  class="w-100"
                  type="password"
                  :rules="{required: true}"
                  name="New Password"
                  label="New Password*"
                  v-model="password"
                  placeholder="">
                </base-input>
              </div>
              <div class="col">
                <base-input
                  class="w-100"
                  type="password"
                  :rules="{required: true}"
                  name="Confirm New Password"
                  label="Confirm New Password*"
                  v-model="confirmPassword"
                  placeholder="">
                </base-input>
              </div>
            </card>
          </form>
          <base-button
            @click="onSubmit()"
            class="btn-primary-action mb-4 br-4 pr-5 pl-5"
            native-type="submit"
            type="submit">
            {{ 'Change Password' }}
          </base-button>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton"

export default {
  name: "ChangePassword",
  components: {
    BackButton
  },
  data() {
    return {
      loaders: {
        program: false
      },
      programId: this.$route.params.id,
      response: {
        program: {...this.$store.getters['BaritasticProgramModule/getBaritasticProgram']}
      },
      password: '',
      confirmPassword: ''
    }
  },
  mounted() {
    this.getBaritasticProgram()
  },
  methods: {
    /**
     * Get Baritastic Program
     */
    getBaritasticProgram() {
      let vm = this
      vm.loaders.program = true
      const payload = {
        id: vm.programId,
      }
      vm.$store.dispatch('BaritasticProgramModule/_getRequestedBaritasticProgram', payload)
        .then(response => {
          vm.response.program = response.data.data
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Baritastic Program',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.program = false
        })
    },

    /**
     * Submit Form Action
     * @returns {Promise<void>}
     */
    async onSubmit() {
      let vm = this
      const isFormValid = await this.$refs['formValidator'].validate()
      if (isFormValid) {
        if (vm.password === vm.confirmPassword) {
          vm.updateProgramPassword()
        } else {
          vm.$notify.error({
            title: 'Validation Error',
            message: 'Confirm Password does not match.'
          })
        }
      } else {
        vm.$notify.error({
          title: 'Validation Error',
          message: 'Please Enter Required Fields.'
        })
      }
    },

    /**
     * Update Program Password
     */
    updateProgramPassword() {
      let vm = this
      vm.loaders.program = true
      const payload = {
        id: vm.programId,
        password: vm.password
      }
      vm.$store.dispatch('BaritasticProgramModule/_updateProgramPassword', {payload})
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Program Password Updated Successfully!'
          })
          vm.$router.back()
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Update Password',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.program = false
        })
    }

  }
}
</script>

<style scoped>

</style>
